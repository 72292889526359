@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=PT+Serif:wght@400;700&family=Roboto:wght@400;700;900&display=swap');
@import "react-alice-carousel/lib/alice-carousel.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

:root {
  --clr-primary: hsl(37, 79%, 59%);
  --clr-secondary: hsl(30, 33%, 92%);
  --clr-tertiary: hsl(20, 60%, 6%);

  --ff-primary: 'Roboto', sans-serif;
  --ff-secondary: 'PT Serif', serif;
  --ff-tertiary: 'Inter', sans-serif;

  --fw-regular: 400;
  --fw-menu: 500;
  --fw-medium: 600;
  --fw-bold: 700;
  --fw-black: 900;

  --fs-11: 0.688rem;
  --fs-12: 0.75rem;
  --fs-14: 0.875rem;
  --fs-15: 0.938rem;
  --fs-16: 1rem;
  --fs-18: 1.125rem;
  --fs-19: 1.188rem;
  --fs-22: 1.375rem;
  --fs-35: 2.188rem;
  --fs-40: 2.5rem;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html, html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

.vertical {
  display: flex;
  flex-direction: column;
}
/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* utility classes */
.text-primary { color: var(--clr-primary); }
.text-secondary { color: var(--clr-secondary); }
.text-tertiary { color: var(--clr-tertiary); }

.bg-primary { background-color: var(--clr-primary); }
.bg-secondary { background-color: var(--clr-secondary); }
.bg-tertiary { background-color: var(--clr-tertiary); }

.ff-primary { font-family: var(--ff-primary); }
.ff-secondary { font-family: var(--ff-secondary); }
.ff-tertiary { font-family: var(--ff-tertiary); }

.fw-regular { font-weight: var(--fw-regular); }
.fw-medium { font-weight: var(--fw-medium); }
.fw-bold { font-weight: var(--fw-bold); }
.fw-black { font-weight: var(--fw-black); }

.fs-16 { font-size: var(--fs-16); }
.fs-14 { font-size: var(--fs-14); }
.fs-18 { font-size: var(--fs-18); }
.fs-19 { font-size: var(--fs-19); }
.fs-22 { font-size: var(--fs-22); }
.fs-40 { font-size: var(--fs-40); }

/* general styling */
body {
  background: #180c06;
  margin: 0 !important;
  padding: 0 !important;
}

p {
  letter-spacing: 0.03em;
  line-height: 1.875rem;
}

li {
  list-style-type: none;
}

button, a {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button:focus, a:focus {
  outline: none !important;
}

h1 {
  font-family: var(--ff-primary);
  font-weight: var(--fw-black);
  color: var(--clr-primary);
  letter-spacing: 0.03rem;
}

header {
  z-index: 2;
}

.index {
  position: relative;
  z-index: 10000;
}

button {
  font-family: var(--ff-primary);
  font-weight: var(--fw-bold);
  color: var(--clr-secondary);
  background: var(--clr-tertiary);
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

.hide {
  display: none;
}

.flex-columns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.links {
  outline: none;
  text-decoration: none;
}



/* ====== desktop responsivity ====== */
@media only screen and (min-width: 980px) {
  html,
  body,
  main {
    width: 100%;
    min-height: 100vh;
}

  .fs-35 { font-size: var(--fs-35) !important; }

  .mobile { display: none; }

  .text-width {
    width: 32vh;
    margin: 0 auto;
  }
  
  .reclamacoes {
    margin-top: 3rem  !important;
  }

  header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 3.75vh;
    background: var(--clr-tertiary);
  }

  .hamb {
    display: none;
  }

  .container {
    width: 82.9vw;
    margin: 0 auto;
    margin-inline: auto;
  }

  .container-nav {
    width: 93vw;
    margin: 0 auto;
    margin-inline: auto;
  }

  /* ====== title/texts ====== */
  .title { 
    font-size: var(--fs-40);
    line-height: 2.938rem;
    margin-bottom: 1.5rem;
  }

  .title-black {
    color: var(--clr-tertiary);
    font-family: var(--ff-primary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-18);
    line-height: 1.813rem;
  }
  
  .text-white {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-18);
    line-height: 1.813rem;
  }

  .text-white-category {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-18);
    line-height: 1.813rem;
  }

  .text-white-small {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-18);
    line-height: 1.813rem;
  }

  .text-description {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-14);
    line-height: 1.75rem;
  }
  
  .text-white-bold {
    color: var(--clr-secondary);
    font-family: var(--ff-primary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-22);
    line-height: 1.813rem;
  }

  .text-white-contacts {
    color: var(--clr-secondary);
    font-family: var(--ff-primary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-22);
    line-height: 1.625rem;
  }

  .text-black {
    color: var(--clr-tertiary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-18);
    line-height: 1.813rem;
  }

  .text-black-bold {
    color: var(--clr-tertiary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-18);
    line-height: 1.813rem;
  }

  .text-black-bold-accordion {
    color: var(--clr-tertiary);
    font-family: var(--ff-primary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-22);
    line-height: 1.813rem;
  }

  .ln-menu {
    line-height: 1.75rem;
  }

  /* ====== header ====== */
  .primary-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .bg-image{
    background: linear-gradient(180deg, #180C06 13.58%, rgba(0, 0, 0, 0) 42.43%), url(../src/assets/imgs/topimage.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    height: 100vh; /* height: 720px; */
  }

  .nav-list {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1vw;
    list-style: none;
    padding-inline-start: 0 !important;
  }

  /* ====== footer ====== */
  .primary-footer {
    padding: 9.83vh 8.5vw 13.25vh 8.5vw;
  }

  .footer-content {
    width: 100%;
  }

  .icons-scale {
    width: 85%;
  }

  .social-scale-1 {
    width: 13vw;
  }

  .social-scale-2 {
    width: 19vw;
  }

  .logo-scale {
    width: 10vw;
  }

  /* ====== buttons ====== */
  .btn-primary {
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration: none;
    padding: 10px 25px;
    color: #f1eae3;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  .btn-secondary{
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration: none;
    padding: 14px 30px;
    border-radius: 18px;
    transition: all 0.3s ease-in-out;
    background-color: #180C06;
  }

  .btn-tertiary {
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration: none;
    padding: 10px 25px 10px 0px;
    color: #f1eae3;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  .btn-primary:hover, .btn-secondary:hover {
    color: #180C06;
    background-color: hsl(37, 79%, 59%);
  }

  .btn-tertiary:hover {
    color: hsl(37, 79%, 59%);
  }

  .btn-border-primary {
    border: 2px solid #F1EAE3;
  }

  .btn-border-primary:hover {
    border: 2px solid hsl(37, 79%, 59%);
  }

  .btn-border-secondary, .btn-border-tertiary {
    border: 2px solid #180C06;
  }

  .btn-border-secondary:hover {
    border: 2px solid hsl(37, 79%, 59%);
  }

  .pageOn { color: var(--clr-primary); }

  .pageOnMenu { 
    color: var(--clr-tertiary); 
    background-color: var(--clr-primary);
    border-color: var(--clr-primary);
  }

  /* ====== gaps ====== */

  /* gaps - vertical */
  .gap-vertical-10 {
    margin-bottom: 1vh;
  }

  .gap-vertical-20 {
    margin-bottom: 2vh;
  }

  .gap-vertical-30 {
    margin-bottom: 3.01vh;
  }

  .gap-vertical-40 {
    margin-bottom: 2.75rem;
  }

  .gap-vertical-50 {
    margin-bottom: 5.7vh;
    margin-top: auto;
  }

  .gap-vertical-60 {
    margin-bottom: 6.04vh;
    margin-top: auto;
  }

  .gap-vertical-85 {
    margin-bottom: 8.55vh;
    margin-top: auto;
  }

  .gap-vertical-100 {
    margin-bottom: 10.08vh;
    margin-top: auto;
  }

  .gap-vertical-130 {
    margin-bottom: 13.05vh;
    margin-top: auto;
  }

  .gap-vertical-150 {
    margin-bottom: 16.31vh;
  }

  .gap-vertical-170 {
    margin-bottom: 10.625rem;
    margin-top: auto;
  }

  .gap-vertical-200 {
    margin-bottom: 20.08vh;
    margin-top: auto;
  }

  /* gaps - left */
  .gap-left-40 {
    margin-left: 2.5rem;
  }

  .gap-left-110 {
    margin-left: 7.64vw;
  }

  .gap-left-120 {
    margin-left: 8.34vw;
  }

  .gap-left-130 {
    margin-left: 9vw;
  }

  .gap-left-150 {
    margin-left: 10.418vw;
  }

  .gap-left-200 {
    margin-left: 13.89vw;
  }

  .gap-left-275 {
    margin-left: 19.1vw;
  }

  /* gaps - right */
  .gap-right-60 {
    margin-right: 4.17vw;
  }

  .gap-right-110 {
    margin-right: 7.64vw;
  }

  .gap-right-150 {
    margin-right: 10.3vw;
  }

  .gap-right-200 {
    margin-right: 20%;
  }

  .gap-right-240 {
    margin-right: 16.67vw;
  }

  .gap-right-295 {
    margin-right: 20.49vw;
  }

  /* gaps - top */
  .gap-top-110 {
    margin-top: 110px;
  }

  .gap-top-200 {
    margin-top: 20.06vh;
  }

  .gap-top-260 {
    margin-top: 28.27vh;
  }

  .padding-top-200 {
    padding-top: 20.06vh;
  }

  .padding-top-260 {
    padding-top: 28.27vh;
  }

  /* ====== displays ====== */
  .flex-center{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .flex-up {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .flex-up-contacts {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .flex-down {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  .flex-left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .columns-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
  }

  .align-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1.875rem;
  }

  .even-columns{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    column-gap: 6rem;
    align-items: end;
  }

  .social-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .map {
    border: 0;
    width: 100%;
    height: 30.313rem;
  }


  /* ====== carousel ====== */
  .carousel .slider {
    max-height: 60vh;
  }

  .carousel .slider-wrapper {
    height: 100%;
  }

  .carousel .slide {
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-wrap: wrap !important;
    opacity: 0.6;
    transition: opacity 1s;
  }

  .carousel .selected {
    opacity: 1;
    transition: opacity 1s;
    align-content: center !important;
  }

  .carousel .slide img {
    height: 100%;
    margin: auto !important;
    -webkit-mask-image: radial-gradient(45% 500% at center, #180C06 1%, rgba(24, 12, 6, 0));
    mask-image: radial-gradient(45% 500% at center, #180C06 1%, rgba(24, 12, 6, 0));
  }

  .carousel .slide div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
  }

  .carousel .selected img {
    height: 100%;
    mask-image: none;
    margin: 0;
  }

  .carousel .selected div {
    height: 100%;
  }

  .control-prev:before {
    content: url(../src/assets/imgs/arrowLT.svg) !important;
  }

  .control-next:before {
    content: url(../src/assets/imgs/arrowRT.svg) !important;
  }

  .carousel .control-next.control-arrow {
    /* margin-right: 12.5vw !important; */
    width: 40%;
    z-index: 1 !important;
    opacity: 0 !important;
  }
  .carousel .control-next.control-arrow:hover {
    opacity: 1 !important;
  }
  .carousel .control-next.control-arrow:before {
    border:none !important;
  }

  .carousel .control-prev.control-arrow {
    /* margin-left: 12.5vw !important; */
    width: 40%;
    z-index: 1 !important;
    opacity: 0 !important;
  }
  .carousel .control-prev.control-arrow:hover {
    opacity: 1 !important;
  }
  .carousel .control-prev.control-arrow:before {
    border:none !important;
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    background: none !important;
  }

  .flex-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10vw;
    margin: 100px auto;
  }

  .dots {
    height: 15px;
    transition: height 0.5s ease-in-out;
  }

  .small {
    height: 8px;
    width: 8px;
    transition: height 0.5s ease-in-out;
  }

  

  /* ====== collapsible ====== */
  .gapClose {
    margin-bottom: 1.25rem;
    width: 69.28%;
    transition: all 0.3s ease-in-out;
  }

  .gapOpen {
    margin-bottom: 1.25rem;
    width: 72%;
    transition: all 0.3s ease-in-out;
  }

  .accordion {
    border: 2px solid #F1EAE3;
    border-radius: 30px;
    transition: background 0.3s ease-in-out;
  }

  .accordion-open {
    border: 2px solid #F1EAE3;
    border-radius: 30px;
  }

  .accordion:hover {
    color:hsl(20, 60%, 6%) !important;
    background-color: hsl(37, 79%, 59%);
    border-color: hsl(37, 79%, 59%);
  }

  .item {
    padding: 3.5vh 5vw 3.5vh 8.5vw;
  }

  .itemOpen {
    padding: 8.7vh 8.55vw 6.53vh 8.55vw;
  }

  .colap-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .content {
    max-height: 0;
    overflow: hidden;
    /* transition: all 0,5s cubic-bezier(0, 1, 0, 1); */
    transition: all 0.3s ease-in-out;
  }

  .content.show {
    max-height: 1000px;
    height: auto;
    /* transition: all 0,5s cubic-bezier(1, 0, 1, 0); */
    transition: all 0.3s ease-in-out;
  }

  .open-close {
    height: 23px;
    width: 23px;
  }

  /* ====== scales ====== */
  .hp-image1 {
    width: 40.419vw;
  }

  .hp-image2 {
    width: 27.78vw;
  }

  .hp-image3 {
    width: 30vw;
  }

  .hp-container1 {
    width: 50vw;
  }

  .hp-container2 {
    width: 81.5vw;
  }

  .au-image1 {
    width: 48.419vw;
  }

  .au-image2 {
    width: 34.9vw;
  }

  .au-image3 {
    width: 50%;
  }

  .au-image4 {
    width: 35%;
  }

  .au-image5 {
    width: 53.5%;
  }

  .au-image6 {
    width: 32%;
  }

  .au-image7 {
    width: 84.48%;
    margin-left: auto;
  }

  .mn-container1 {
    width: 70.3%;
    margin: 0 auto;
  }
  
  .mn-image1 {
    width: 42.8vw;
  }

  .ct-container {
    width: 83vw;
    margin: 0 auto;
  }

  
}

/* ====== mobile responsivity ====== */
@media only screen and (max-width: 980px) {
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh !important;
    min-height: -moz-available !important;
    min-height: -webkit-fill-available !important;
  }

  html {
    height: -webkit-fill-available;
  }

  .desktop { display: none; }
  .bg-image{
    background: linear-gradient(180deg, #180C06 10.58%, rgba(0, 0, 0, 0) 42.43%), url(../src/assets/imgs/topimage.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 23% center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    height: 100vh;
  }
  
  header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--clr-tertiary);
  }

  /* ====== buttons ====== */
  button {
    border: none;
    outline: none;
  }

  .btn-secondary{
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration: none;
    padding: 12px 18px;
    border-radius: 12px;
    background-color: #180C06;
  }

  .btn-border-secondary {
    border: 1px solid #180C06;
    border-right-width: 4px;
    border-left-width: 4px;
  }

  .btn-border-primary {
    border: 1px solid #F1EAE3;
  }

  /* ====== title/texts ====== */
  .title {
    font-size: 1.625rem;
    line-height: 1.5rem;
    margin-bottom: 1.875rem;
  }

  .title-black {
    color: var(--clr-tertiary);
    font-family: var(--ff-primary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-18);
    line-height: 1.5rem;
  }

  .text-white {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-15);
    line-height: 1.5rem;
  }

  .text-white-small {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-12);
    line-height: 1rem;
  }

  .text-white-bold {
    color: var(--clr-secondary);
    font-family: var(--ff-primary);
    font-weight: var(--fw-menu);
    font-size: var(--fs-16);
    line-height: 1.5rem;
  }

  .text-white-category {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-15);
    line-height: 1.5rem;
  }

  .text-white-contacts{
    color: var(--clr-secondary);
    font-family: var(--ff-primary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-19);
    line-height: 1.5rem;
  }

  .text-description {
    color: var(--clr-secondary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-11);
    line-height: 1rem;
  }

  .text-black {
    color: var(--clr-tertiary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-regular);
    font-size: var(--fs-15);
    line-height: 1.5rem;
  }

  .text-black-bold {
    color: var(--clr-tertiary);
    font-family: var(--ff-secondary);
    font-weight: var(--fw-bold);
    font-size: var(--fs-15);
    line-height: 1.5rem;
  }

  .ln-menu { line-height: 1rem;}

  /* ====== containers ====== */
  .container-mobile {
    width: 87.17vw;
    margin: 0 auto;
  }

  .flex-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1vw;
    margin-bottom: 3vh;
  }

  .flex-mobile-down {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1vw;
  }

  .flex-mobile-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1vw;
  }

  .flex-up {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .container-nav {
    width: 87.15vw;
    margin: 2.97vh auto;
  }

  .mobile-social {
    width: 28.97vw;
  }

  .mobile-arrow {
    width: 12vw;
  }

  .primary-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .align-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1.875rem;
  }
  
  .nav-logo {
    width: 6.75rem;
  }
  
  .nav-list {
    display: none;
  }

  .fs-16 {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  .map {
    border: 0;
    width: 100%;
    height: 23.7vh;
  }

  .logo-scale {
    max-height: 30px;
    width: auto;
  }

  /* ====== gaps ====== */
  .gap-mobile-10 {
    margin-bottom: 1.19vh;
    margin-top: auto;
  }

  .gap-mobile-15 {
    margin-bottom: 1.78vh;
    margin-top: auto;
  }

  .gap-mobile-20 {
    margin-bottom: 2.37vh;
    margin-top: auto;
  }

  .gap-mobile-25 {
    margin-bottom: 2.97vh;
    margin-top: auto;
  }

  .gap-mobile-30 {
    margin-bottom: 3.58vh;
    margin-top: auto;
  }

  .gap-mobile-40 {
    margin-bottom: 4.8vh;
    margin-top: auto;
  }

  .gap-mobilet-60 {
    margin-top: 7.1vh;
  }

  .gap-mobileb-60 {
    padding-bottom: 7.1vh;
  }

  .gap-mobile-70 {
    margin-bottom: 8.3vh;
    margin-top: auto;
  }

  .gap-mobile-80 {
    margin-bottom: 9.5vh;
    margin-top: auto;
  }

  .gap-mobile-85 {
    margin-bottom: 10.1vh;
    margin-top: auto;
  }

  .gap-mobileb-90 {
    margin-bottom: 10.7vh;
    margin-top: auto;
  }

  .gap-mobile-90 {
    padding-top: 15.06vh;
  }

  .gap-mobile-100 {
    margin-bottom: 11.9vh;
  }

  .gap-mobilet-110 {
    margin-top: 13vh;
  }

  .gap-mobilet-120 {
    padding-top: 14.3vh;
  }

  .gap-mobilev-130 {
    margin-bottom: 16.11vh;
    margin-top: auto;
  }

  .gap-mobile-200 {
    margin-bottom: 23.7vh;
  }

  .icons-scale {
    width: 85%;
    max-height: 30px;
  }

  /* ====== collapsible ====== */

  .accordion, .accordion-open {
    border: 1px solid #F1EAE3;
    border-radius: 12px;
  }

  .item {
    padding: 2vh 7.7vw;
    transition: all 0.3s ease-in-out;
  }

  .itemOpen {
    padding: 2.8vh 7.7vw;
    transition: all 0.3s ease-in-out;
  }

  .colap-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .content.show {
    max-height: 1000px;
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .open-close {
    height: 23px;
    width: 23px;
  }

  /* ====== carousel ====== */
  .carousel .slider {
    height: 27vh; /* max-height: 27vh; */
  }

  .carousel .slide {
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-wrap: wrap !important;
    align-content: center;
  }

  .carousel .selected {
    align-content: center !important;
  }

  .carousel .previous {
    align-content: center;
  }

  .carousel .slide img {
    height: 100%;
    width: auto !important;
    margin: auto !important;
    opacity: 0.4;
  }

  .carousel .slide div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
  }

  .carousel .selected img {
    height: 100%;
    mask-image: none;
    margin: 0;
    opacity: 1;
  }

  .carousel .selected div {
    height: 100%;
  }

  .flex-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 15vw;
    margin: 30px auto;
  }

  .dots {
    height: 10px;
    transition: height 0.5s ease-in-out;
  }

  .small {
    height: 5px;
    width: 5px;
    transition: height 0.5s ease-in-out;
  }

  .reclamacoes {
    margin-top: 1rem ;
    margin-bottom: 1rem;
  }
  

  }
